import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Faqs from "../components/Faqs";

export const FaqPageTemplate = ({ title, faq, helmet }) => (
  <>
  <div className="bg-indigo-800 hero">
    {helmet || ""}
    <h1 className="font-serif text-4xl md:pt-10 md:pb-32 md:text-7xl py-10 text-4xl text-center text-white">{title}</h1>
  </div>
  <div className="bg-gray-100 pb-56">
    <div className="max-w-screen-xl md:py-20 mx-auto px-8 py-10">
      <Faqs FaqItems={faq} />
    </div>
  </div>
  </>
);

FaqPageTemplate.propTypes = {
  title: PropTypes.string,
  faq: PropTypes.array,
};

const FaqPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FaqPageTemplate
        title={frontmatter.title}
        faq={frontmatter.faq}
        helmet={
          <Helmet>
            <title>{frontmatter.title} | CVision AI</title>
          </Helmet>
        }
      />
    </Layout>
  );
};

FaqPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FaqPage;

export const aboutPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        faq {
          question
          answer
        }
      }
    }
  }
`;