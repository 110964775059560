import React from "react";
import PropTypes from "prop-types";
import faqStyles from "./Faqs.module.scss";

const FaqItem = ({ FaqItems }) => (
  <div>
    {FaqItems.map((item) => (
      <details
        key={item.question}
        className="block border-b border-gray-300 flex md:text-lg text-gray-800"
      >
        <summary
          className={faqStyles.summaryWrapper + " focus:outline-none md:text-3xl text-indigo-900 text-lg"}
        >
          <div className={faqStyles.summary + " flex items-center justify-between relative md:py-8 py-6"}>
            <span className="max-w-3xl md:mr-6 sm:w-4/5 mr-2">
              {item.question}
            </span>
            <span className={faqStyles.summaryIcon + " border border-gray-400 cursor-pointer flex h-8 items-center justify-center md:h-10 md:p-3 md:w-10 mx-2 p-2 right-0 rounded-full top-0 w-8"}></span>
          </div>
        </summary>
        <p className="max-w-3xl leading-loose md:mb-12 w-4/5 pb-8">
          {item.answer}
        </p>
     </details>
    ))}
  </div>
);

FaqItem.propTypes = {
  Items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes,
      answer: PropTypes.string,
    })
  ),
};

export default FaqItem;